import styles from './Order.module.scss';
import classNames from 'classnames/bind';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import ProductBox from './ProductBox';
import { useEffect, useRef, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useTranslation } from 'react-i18next';
import './Order.css';
import logoFreelancer from '../../assets/images/logo_freelancer.png';
import startIcon from '../../assets/images/start.png';

import audio from '../../assets/audio/hongbao.mp3';
import axios from 'axios';
import khoa from '../../assets/images/khoa.png';

import lining from '../../assets/images/new/don1.jpg';
import maxxsport from '../../assets/images/new/don2.jpg';
import nike from '../../assets/images/new/don3.jpg';
import adidas from '../../assets/images/new/4. ADIDAS.png';
import asics from '../../assets/images/new/5. ASICS.png';
import i361 from '../../assets/images/new/6. 361°.png';
import banner1 from '../../assets/images/banner-freelancer-1.jpg';
import banner2 from '../../assets/images/banner-freelancer-2.jpg';
import banner3 from '../../assets/images/banner-freelancer-3.jpg';
import bgMoney from '../../assets/images/bg-infoMoney.jpg';
import bgRose from '../../assets/images/bg-rose.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';

const cx = classNames.bind(styles);

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const normalFormatter = new Intl.NumberFormat();

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Order() {
    const { id_mission } = useParams();

    const { t } = useTranslation();

    // const [isDisableButton, setIsDisableButton] = useState(true);

    // const currentTime = new Date().getHours();

    // useEffect(() => {
    //     if (currentTime >= 9 && currentTime <= 22) {
    //         setIsDisableButton(false);
    //     }
    // }, [currentTime]);

    // const [listMission, setListMission] = useState([
    //     {
    //         icon: bacDoan,
    //         rose: 20,
    //         content: '2 đơn hàng',
    //         status: 1,
    //     },
    //     {
    //         icon: vangDoan,
    //         rose: 25,
    //         content: '3 đơn hàng',
    //         status: 1,
    //     },
    //     {
    //         icon: bachKim,
    //         rose: 30,
    //         content: '5 đơn hàng',
    //         status: 0,
    //     },
    //     {
    //         icon: kimCuong,
    //         rose: 35,
    //         content: '7 đơn hàng',
    //         status: 0,
    //     },
    //     {
    //         icon: kimCuong,
    //         rose: 30,
    //         content: '8 đơn hàng',
    //         status: 0,
    //     },
    //     {
    //         icon: kimCuong,
    //         rose: 30,
    //         content: '10 đơn hàng',
    //         status: 0,
    //     },
    // ]);

    const [listMission, setListMission] = useState([
        {
            title: 'ĐƠN HÀNG 1',
            icon: lining,
            rose: 20,
            content: 1,
            status: 1,
            name: 'ĐƠN HÀNG 1',
        },
        {
            title: 'ĐƠN HÀNG 2',
            icon: maxxsport,
            rose: 25,
            content: 3,
            status: 0,
            name: 'ĐƠN HÀNG 2',
        },
        {
            title: 'ĐƠN HÀNG 3',
            icon: nike,
            rose: 30,
            content: 5,
            status: 0,
            name: 'ĐƠN HÀNG 3',
        },
        {
            title: 'ĐƠN HÀNG 4',
            icon: adidas,
            rose: 35,
            content: 7,
            status: 0,
            name: 'ĐƠN HÀNG 4',
        },
        {
            title: 'ĐƠN HÀNG 5',
            icon: asics,
            rose: 30,
            content: 8,
            status: 0,
            name: 'ĐƠN HÀNG 5',
        },
        {
            title: 'ĐƠN HÀNG 6',
            icon: i361,
            rose: 30,
            content: 10,
            status: 0,
            name: 'ĐƠN HÀNG 6',
        },
    ]);

    const [progress, setProgress] = useState(0);
    const [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0, roses: 0 }],
        moneyEarn: { moneyEarnPreDay: 0, moneyEarnToday: 0 },
        mission: {
            amount: 0,
            result: 0,
            amountToday: 0,
        },
    });

    const navigate = useNavigate();

    const [isMatching, setIsMatching] = useState(false);

    const handSendMission = async () => {
        setIsMatching(true);
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/new`,
                { level: id_mission },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setIsMatching(false);
                    if (data.data.type === 1) {
                        toast.success(`${t('content.order.timThay1DonHang')}`, { theme: 'light' });
                        await sleep(1000);
                        setTimeout(() => {
                            navigate(`/order/index`);
                        }, 1000);
                        return;
                    }
                    if (data.data.type === 2) {
                        toast.warn(`Hiện tại hệ thống chưa có đơn hàng phân phối, vui lòng thử lại sau!`, {
                            theme: 'light',
                        });
                        return;
                    }
                    if (data.data.type === 3) {
                        toast.warn(`${t('content.order.tkBiKhoa')}`, { theme: 'light' });
                        return;
                    }
                    if (data.data.type === 1000) {
                        toast.success(`${t('content.order.daHTDuDon')}`, { theme: 'light' });
                        return;
                    }
                    if (data.data.type === 1001) {
                        toast.warn(`Bạn có đơn chưa hoàn thành`, { theme: 'light' });
                        return;
                    }
                }
            })
            .catch(function (error) {
                setIsMatching(false);
                if (error.response.status === 403) return toast.warn(error.response.data, { theme: 'light' });
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    };
    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    setUser(data);
                    const updateStatus = (name_level) => {
                        let updatedList = [];
                        switch (name_level) {
                            case 'ĐƠN HÀNG 1':
                                updatedList = listMission.map((mission) =>
                                    mission.title === name_level
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'ĐƠN HÀNG 2':
                                updatedList = listMission.map((mission) =>
                                    ['ĐƠN HÀNG 1', 'ĐƠN HÀNG 2'].includes(mission.title)
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'ĐƠN HÀNG 3':
                                updatedList = listMission.map((mission) =>
                                    ['ĐƠN HÀNG 1', 'ĐƠN HÀNG 2', 'ĐƠN HÀNG 3'].includes(mission.title)
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'ĐƠN HÀNG 4':
                                // updatedList = listMission.map((mission) => ({ ...mission, status: 1 }));
                                updatedList = listMission.map((mission) =>
                                    ['ĐƠN HÀNG 1', 'ĐƠN HÀNG 2', 'ĐƠN HÀNG 3', 'ĐƠN HÀNG 4'].includes(mission.title)
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'ĐƠN HÀNG 5':
                                // updatedList = listMission.map((mission) => ({ ...mission, status: 1 }));
                                updatedList = listMission.map((mission) =>
                                    ['ĐƠN HÀNG 1', 'ĐƠN HÀNG 2', 'ĐƠN HÀNG 3', 'ĐƠN HÀNG 4', 'ĐƠN HÀNG 5'].includes(
                                        mission.title,
                                    )
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'ĐƠN HÀNG 6':
                                updatedList = listMission.map((mission) => ({ ...mission, status: 1 }));
                                break;
                            default:
                                updatedList = listMission;
                        }
                        setListMission(updatedList);
                    };

                    updateStatus(data.data[0].name_level);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 1);
            }
        }, 30);

        return () => clearInterval(intervalId);
    }, [progress]);

    return (
        <div className="main-container">
            {isMatching && (
                <div className="matching-animation">
                    <div className="overlay">
                        <div className="text flex">{t('content.order.khopTuDong')}</div>
                        <div className="loader loader1"></div>
                        <div className="loader loader2"></div>
                        <div className="loader loader3"></div>
                    </div>
                </div>
            )}

            <div style={{ backgroundColor: '#1b2632' }}>
                <img src={logoFreelancer} alt="" width={120} className="px-2 py-3" />
            </div>
            {/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream ? (
                <Swiper
                    className="mySwiper"
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    <SwiperSlide>
                        <img src={banner1} alt="banner1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={banner2} alt="banner1" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={banner3} alt="banner1" />
                    </SwiperSlide>
                </Swiper>
            ) : (
                <video loop autoPlay muted playsInline>
                    <source src={require('../../assets/images/video.mp4')} type="video/mp4" />
                </video>
            )}

            <div className="flex items-center justify-center mt-12">
                <img src={startIcon} alt="" width={80} onClick={handSendMission} />
            </div>

            <div className="text-3xl font-bold text-center py-4   text-white">{t('content.order.thanhQuaHomNay')}</div>
            <div className={` rounded-xl mx-2 `} style={{ backgroundImage: `url(${bgMoney})` }}>
                <div className="p-3 grid grid-cols-2 gap-5">
                    <div className="flex flex-col items-center justify-center">
                        <div className=" text-black text-xl">{t('content.order.soDuTK')}</div>
                        <div className=" text-[#fc0303] text-3xl py-2">
                            {formatMoney(Number(user?.data[0].money))} đ
                        </div>
                        <div className=" text-black text-xl pt-8">{t('content.order.hoaHongHomNay')}</div>
                        <div className=" text-[#fc0303] text-3xl py-2">
                            {formatMoney(Number(user?.moneyEarn.moneyEarnToday))} đ
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center">
                        <div className=" text-black text-xl">{t('content.order.soDonHoanThanh')}</div>
                        <div className=" text-[#fc0303] text-3xl py-2">{user?.mission?.amountToday}</div>
                        <div className=" text-black text-xl pt-8">{t('content.order.soTienDongBang')}</div>
                        <div className=" text-[#fc0303] text-3xl py-2">
                            {formatMoney(Number(user?.data[0].dongbangtk))} đ
                        </div>
                    </div>
                </div>
            </div>
            <div className="goodcats  mb-[70px]">
                {listMission.map((item, i) => {
                    return (
                        <div className="cats " key={i}>
                            {/* <div className="z-10">
                                <h4 className="text-center text-[white] text-2xl font-bold mt-2">{item.title}</h4>
                            </div> */}

                            <div className="flex items-center justify-center mt-2">
                                {item.status ? (
                                    <div
                                        // to="/order/mission"
                                        className="central-group-img z-10 w-[90%] h-[100%]"
                                        style={{ height: '105px' }}
                                    >
                                        <img src={item.icon} alt="" className="m-auto h-[100%]" width={'200px'} />
                                    </div>
                                ) : (
                                    <div
                                        className="central-group-img z-10 w-[90%] h-[100%]  relative "
                                        style={{ height: '105px' }}
                                    >
                                        <img src={item.icon} alt="" width={'200px'} className="h-[100%]" />
                                        <div className="bg-khoa w-[100%] h-[100%] flex flex-col items-center justify-center">
                                            <img src={khoa} alt="" />
                                            <span className="text-white text-2xl font-bold">
                                                {t('content.home.choNangCap')}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <img src={bgRose} alt="" className="w-full h-[60px]" />
                            {/* 
                            <div className="flex gap-3 items-center">
                                <p
                                    className="text-xl font-bold  mt-3  mb-0 text-white"
                                    style={{
                                        padding: '10px 5px 10px 5px',
                                        borderRadius: '0px 3px 3px 5px',
                                        backgroundImage: `url(${bgRose})`,
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    {t('content.home.hoaHong')}
                                    {item.rose}%
                                </p>
                            </div>
                            <p className="text-xl mt-4 ">{item.content} đơn hàng</p> */}
                            <p className="absolute bottom-4 left-[7%]  text-2xl font-semibold">{item.rose}%</p>
                            <p className="absolute bottom-5 left-[27%]  text-3xl font-semibold">{item.title}</p>
                            <p className="absolute bottom-[-3px] left-[30%]  text-xl ">{item.content} đơn hàng</p>
                        </div>
                    );
                })}
            </div>
            {/* <div className="task_hall_sheet">
                <div className="task_hall_sheet_box w-[100%] bg-[#f5f5f5] mt-24 rounded-3xl relative flex flex-col items-center justify-center">
                    <img src={centralOrder} alt="" className="w-[90%] pt-3" />
                    <ListHome amount={1} type="order" />
                    <img
                        src={startOrder}
                        alt=""
                        className="w-[50%] cursor-pointer"
                        onClick={() => {
                            setIsMatching(true);
                            handAnimateMission();
                        }}
                    />
                </div>
            </div> */}

            {/*
            <div className="luu-y-order bg-[#f5f5f5] mt-8 flex flex-col items-center justify-center rounded-xl">
                <br />
                <span className="bg-[#2f3848] px-4 py-2 rounded-xl text-[#f2d8be] text-xl w-[30%] flex items-center justify-center mb-4">
                    {t('content.order.giaiMa')}
                </span>
            </div>
            <div className="relative bg-[#f5f5f5] mt-8 flex flex-col items-center justify-center rounded-xl">
                <div className="huong-dan py-2 px-4 rounded-xl">
                    <span className="text-white text-xl text-center">Mô tả</span>
                </div>
                <div className="content-huong-dan py-4 px-4">
                    <span className="text-black text-xl px-4 pb-2 text-center leading-10">
                        1. Mỗi tài khoản có thể được phân bổ ngẫu nhiên đơn đặt hàng mỗi ngày.
                    </span>
                    <br />
                    <span className="text-black text-xl px-4 pb-2 text-center leading-10">
                        2. Lợi nhuận mỗi đơn đặt hàng cao nhất là 50% số tiền đặt hàng.
                    </span>
                    <br />
                    <span className="text-black text-xl px-4 pb-2 text-center leading-10">
                        3. Hệ thống dựa trên công nghệ LBS và tự động duyệt các sản phẩm thông qua đám mây.
                    </span>
                    <br />
                    <span className="text-black text-xl px-4 pb-2 text-center leading-10">
                        4. Để ngăn chặn sự giám sát của nền tảng,nếu đơn hàng không được xác nhận và gửi trong vòng 120
                        phút, hệ thống sẽ đóng băng số tiền đơn hàng.
                    </span>
                </div>
                <span className="text-black text-xl px-4 pb-2 text-center leading-10">
                    <strong>Lưu ý: </strong>
                    Khi nền tảng duyệt đơn đặt hàng,nền tảng này sẽ gửi thông tin của đơn hàng đến trung tâm thương mại.
                    Nếu người tiêu dùng không gửi đơn hàng trong vòng 120 phút, số tiền đơn hàng sẽ bị hệ thống đóng
                    băng. Để lách hệ thống giám sát, tài khoản tiêu người dùng sẽ bị trừ điểm, ảnh hưởng trực tiếp đến
                    thời gian và đơn hàng của lần tiếp theo của người tiêu dùng.
                </span>
            </div> */}

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Order;
